<template>
    <v-card
        class="qty-component text-no-wrap"
        :elevation="inEditMode ? 2 : 0"
        v-click-outside="{
            handler: commitUpdate,
            closeConditional: () => inEditMode
        }"
    >
        <v-btn
            v-if="!inEditMode"
            :ripple="false"
            :disabled="disabled || qty === min"
            variant="plain"
            size="small"
            aria-label="Decrement Quantity"
            @click="onChangeValue(-1)"
            icon
        >
            <v-icon
                size="32"
                color="secondary"
                :icon="mdiMinusBox"
            ></v-icon>
        </v-btn>

        <span
            v-if="!inEditMode"
            class="qty-value text-h6 v-align-middle text-center d-inline-block"
            :class="{ 'cursor-pointer': !disabled && !disableEdit, 'text-disabled': disabled }"
            @click="enterEditMode()"
        >
            {{ qty }}
        </span>
        <div
            v-else
            class="d-flex"
        >
            <v-input class="qty-value-input" hide-details>
                <input
                    v-model.number="qtyInput"
                    name="qty-value-input"
                    type="number"
                    class="v-align-middle text-center"
                    ref="qtyValueInput"
                    :disabled="disabled"
                    @keyup.escape.prevent="commitUpdate()"
                    @keyup.enter.prevent="commitUpdate()"
                    @keydown.enter.prevent
                />
            </v-input>
            <v-btn
                :ripple="false"
                type="submit"
                color="green-darken-2"
                variant="plain"
                size="small"
                icon
            >
                <v-icon size="32" :icon="mdiCheckboxMarked"></v-icon>
            </v-btn>
            <v-btn
                :ripple="false"
                type="button"
                color="red-darken-2"
                variant="plain"
                size="small"
                @click="cancelEditMode()"
                icon
            >
                <v-icon size="32" :icon="mdiCloseBox"></v-icon>
            </v-btn>
        </div>

        <v-btn
            v-if="!inEditMode"
            :ripple="false"
            @click="onChangeValue(1)"
            :disabled="disabled || qty === max"
            variant="plain"
            size="small"
            aria-label="Increment Quantity"
            icon
        >
            <v-icon
                size="32"
                color="secondary"
                :icon="mdiPlusBox"
            ></v-icon>
        </v-btn>
    </v-card>
</template>

<script lang="ts" setup>
    import { mdiMinusBox, mdiCheckboxMarked, mdiCloseBox, mdiPlusBox } from '@mdi/js';
    import { watchPausable } from '@vueuse/core';

    const props = defineProps({
        modelValue: {
            type: Number,
            default: 1,
            required: true
        },
        minValue: {
            type: Number,
            default: 1
        },
        maxValue: {
            type: Number,
            default: 99
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableEdit: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number): void,
        (e: 'change', value: number): void
    }>();

    const qty = ref(props.modelValue);
    const qtyInput = ref(props.modelValue);
    const min = ref(props.minValue);
    const max = ref(props.maxValue);
    const inEditMode = ref(false);
    const qtyValueInput = ref<HTMLInputElement>();

    const {
        stop: unWatchQty,
        pause: pauseWatchQty,
        resume: resumeWatchQty
    } = watchPausable(
        () => props.modelValue,
        (newQty, oldQty) => {
            if (newQty !== oldQty) {
                qty.value = newQty;
            }
        }
    );

    function onChangeValue(change: number): void {
        if (inEditMode.value) {
            return;
        }

        if (!Number.isInteger(props.modelValue) || qty.value + change < min.value || qty.value + change > max.value) {
            return;
        }

        pauseWatchQty();
        qty.value += change;
        emit('update:modelValue', qty.value);
        emit('change', qty.value);
        setTimeout(() => resumeWatchQty, 400);
    }

    function commitUpdate(): void {
        if (!inEditMode.value) {
            return;
        }

        inEditMode.value = false;

        if (!Number.isInteger(qtyInput.value) || qtyInput.value < min.value || qtyInput.value > max.value) {
            return;
        }

        // Don't update if the value hasn't changed
        if (qty.value === qtyInput.value) {
            return;
        }

        qty.value = qtyInput.value;

        emit('update:modelValue', qty.value);
        emit('change', qty.value);
    }

    function enterEditMode(): void {
        if (props.disabled || props.disableEdit) {
            return;
        }

        qtyInput.value = qty.value;
        inEditMode.value = true;

        setTimeout(() => {
            qtyValueInput.value?.focus();
        }, 200);
    }

    function cancelEditMode(): void {
        qtyInput.value = 0;
        inEditMode.value = false;
    }

    onBeforeUnmount(() => {
        unWatchQty();
    });
</script>

<style lang="scss">
.qty-component {
    user-select: none;
}

.qty-value {
    width: 1.8rem;

    &-input {
        display: inline-block;
        width: 32px;
        height: 36px;
        margin: 0;
        padding: 0;

        input {
            background: transparent;

            width: 32px;
            height: 36px;
            margin: 0;
            padding: 0;

            text-align: center;
            font-weight: bold;
            border: none;

            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &:focus {
                outline: none;
            }
        }
    }
}
</style>
